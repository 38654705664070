import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'Settings',
    data() {
        return {
            business_name: null,
            contact_name: null,
            business_email_address: null,
            youtube_channel_link: null
        }
    },
    components: { adminLayout },
    mounted() {
        this.getSettings()
    },
    methods: {
        getSettings() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/options-simple',
                method: 'GET'
            }).then(function(response) {
                response.data.forEach(item => {
                    if(item.key == 'business_name') {
                        self.business_name = item.value
                    }

                    if(item.key == 'contact_name') {
                        self.contact_name = item.value
                    }

                    if(item.key == 'business_email_address') {
                        self.business_email_address = item.value
                    }

                    if(item.key == 'youtube_channel_link') {
                        self.youtube_channel_link = item.value
                    }
                })
            })
        },
        updateSettings() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/options',
                method: 'PUT',
                data: {
                    business_name: self.business_name,
                    contact_name: self.contact_name,
                    business_email_address: self.business_email_address,
                    youtube_channel_link: self.youtube_channel_link
                }
            }).then(function() {
                self.notifySuccess()
            }) 
        }
    }
}